import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
//import { parseTime } from '../../../../utils/convert.js'
import {
	Collapse,
	CollapseItem,
	DatetimePicker
} from 'vant';
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatetimePicker);
Vue.use(Vant);
export default {
	name: "AddCsiiLifestyleList",
	data() {
		return {
			activeName: ['1'],
			CsiiDetail: {},
			patientUuid: '',
			uuid: '',
			token: '',
			lunchshow: false,
			dinnershow: false,
			snackshow: false,
			breakshow: false,
			bloodshow: false,
			date: '',
			currentTime: '',
			updateTime: '',
			//基础信息
			BasicInformation: {
				weight: '',
				sleepHours: '',
			},
			//运动
			Sports: {
				trainWay: '',
				trainMinutes: '',
			},
			//药物
			Medicine: {
				medicalType: '',
				medicalDosage: '',
			},
			//饮食
			DietForm: {
				snackWeight: '',
				snackTime: '',
				snackDesc: '',
				lunchWeight: '',
				lunchTime: '',
				lunchDesc: '',
				dinnerWeight: '',
				dinnerTime: '',
				dinnerDesc: '',
				breakfastWeight: '',
				breakfastTime: '',
				breakfastDesc: '',
			},
			//血糖检测
			bloodSugarCheck: {
				bloodSugarCheckTime: '',
				bloodSugarCheckResult: '',
			},
			csiiLifeMedicalForm: {},
			condition: Number,
		}
	},
	mounted() {
		this.token = this.$route.query.token;
		this.uuid = this.$route.query.uuid;
		this.patientUuid = this.$route.query.patientUuid;
		// this.CsiiDetail = this.$route.query.CsiiDetail;
		this.condition = this.$route.query.condition;
		this.addDate();
		this.getCsiiDetail();
	},
	methods: {
		showPopup() {
		      this.show = true;
		    },
		onBreakfast(value) {
			this.breakshow = false;
			this.DietForm.breakfastTime = this.updateTime + ' ' + value + ':00';
			this.DietForm.breakfastTimes = value;
			console.log("早餐")
			console.log(this.DietForm.breakfastTime)
		},
		onLunch(value) {
			this.lunchshow = false;
			this.DietForm.lunchTime = this.updateTime + ' ' + value + ':00';
			this.DietForm.lunchTimes = value;
			console.log("中餐")
		},
		onDinner(value) {
			this.dinnershow = false;
			this.DietForm.dinnerTime = this.updateTime + ' ' + value + ':00';
			this.DietForm.dinnerTimes = value;
			console.log("晚餐")
		},
		onSnack(value) {
			this.snackshow = false;
			this.DietForm.snackTime = this.updateTime + ' ' + value + ':00';
			this.DietForm.snackTimes = value;
			console.log(value)
			console.log("零食")
		},
		onBlood(value) {
			console.log(value)
			this.bloodshow = false;
			this.bloodSugarCheck.bloodSugarCheckTime = this.updateTime + ' ' + value + ':00';
			console.log(this.bloodSugarCheck.bloodSugarCheckTime)
			this.bloodSugarCheck.bloodSugarCheckTimes = value;
			console.log("血糖")
		},
		addDate() {
			const nowDate = new Date();
			const date = {
				year: nowDate.getFullYear(),
				month: nowDate.getMonth() + 1,
				date: nowDate.getDate(),
			}
			const newmonth = date.month > 10 ? date.month : '0' + date.month
			const day = date.date > 10 ? date.date : '0' + date.date
			this.updateTime = date.year + '-' + newmonth + '-' + day
		},
		//基础信息
		BIsubmit() {
			let that = this;
			
			if (that.BasicInformation.weight.length > 5) {
				that.$message.error("体重超出范围，最多5位数！");
				return;
			} else if (that.BasicInformation.weight < 1) {
				that.$message.error("体重内容填写不正确！");
				return;
			} else {
				let decimalW = that.BasicInformation.weight.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("体重超出范围，最多2位小数！");
					return;
				}
			}

			if (that.BasicInformation.sleepHours.length > 5) {
				setTimeout(() => {
					that.$message.error("睡眠时间过长，最多5位数！");
				}, 10);
				return;
			} else if (that.BasicInformation.sleepHours < 1) {
				that.$message.error("睡眠时间填写不正确！");
				return;
			} else {
				let decimalW = that.BasicInformation.sleepHours.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("睡眠时间超出范围，最多2位小数！");
					return;
				}
			}

			that.csiiLifeMedicalForm = that.BasicInformation;
			that.onSubmit();
		},
		//运动
		SPsubmit() {
			let that = this;
			if (that.Sports.trainWay.length > 9) {
				that.$message({
					message: "运动方式填写过长，最多可以填写9个字！",
					type: "error",
				});
				return;
			}
			if (that.Sports.trainMinutes.length > 5) {
				that.$message({
					message: "运动时长填写过长，最多5位数！",
					type: "error",
				});
				return;
			} else if (that.Sports.trainMinutes < 0) {
				that.$message.error("运动时长填写不正确！");
				return;
			} else {
				let decimalW = that.Sports.trainMinutes.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("运动时长超出范围，最多2位小数！");
					return;
				}
			}

			that.csiiLifeMedicalForm = that.Sports;
			that.onSubmit();
		},
		//药物
		MEsubmit() {
			let that = this;
			// if (that.Medicine.medicalType.length < 10 && that.Medicine.medicalDosage.length < 10) {
			// 	that.csiiLifeMedicalForm = this.Medicine;
			// 	this.onSubmit();
			// }
			if (that.Medicine.medicalType.length > 9) {
				that.$message({
					message: "药物种类填写过长，最多可以填写9个字！",
					type: "error",
				});
				return;
			}
			if (that.Medicine.medicalDosage.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "服用方法填写过长，最多可以填写9个字！",
						type: "error",
					});
				}, 10);
				return;
			}

			that.csiiLifeMedicalForm = that.Medicine;
			that.onSubmit();
		},
		//饮食
		DIsubmit() {
			let that = this;
			// if (that.DietForm.snackWeight.length < 10 && that.DietForm.snackDesc.length < 10 && that.DietForm
			// 	.lunchWeight.length < 10 && that.DietForm.lunchDesc.length < 10 && that.DietForm.dinnerWeight.length <
			// 	10 && that.DietForm.dinnerDesc.length < 10 && that.DietForm.breakfastWeight.length < 10 && that.DietForm
			// 	.breakfastDesc.length < 10) {
			// 	that.csiiLifeMedicalForm = this.DietForm;
			// 	this.onSubmit();
			// }

			if (that.DietForm.breakfastDesc.length > 9) {
				that.$message({
					message: "您填写的早餐内容文字过长，最多可以填写9个字！",
					type: "error",
				});
				return;
			}
			if (that.DietForm.breakfastWeight.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的早餐重量文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 5);
				return;
			} else if (that.DietForm.breakfastWeight < 1) {
				that.$message.error("早餐重量填写不正确！");
				return;
			} else {
				let decimalW = that.DietForm.breakfastWeight.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("早餐重量超出范围，最多2位小数！");
					return;
				}
			}

			if (that.DietForm.lunchDesc.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的中餐内容文字过长，最多可以填写9个字！",
						type: "error",
					});
				}, 10);
				return;
			}
			if (that.DietForm.lunchWeight.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的中餐重量文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 15);
				return;
			} else if (that.DietForm.lunchWeight < 1) {
				that.$message.error("中餐重量填写不正确！");
				return;
			} else {
				let decimalW = that.DietForm.lunchWeight.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("中餐重量超出范围，最多2位小数！");
					return;
				}
			}


			if (that.DietForm.dinnerDesc.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的晚餐内容文字过长，最多可以填写9个字！",
						type: "error",
					});
				}, 20);
				return;
			}
			if (that.DietForm.dinnerWeight.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的晚餐重量文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 25);
				return;
			} else if (that.DietForm.dinnerWeight < 1) {
				that.$message.error("晚餐重量填写不正确！");
				return;
			} else {
				let decimalW = that.DietForm.dinnerWeight.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("晚餐重量超出范围，最多2位小数！");
					return;
				}
			}

			if (that.DietForm.snackDesc.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的零食内容文字过长，最多可以填写9个字！",
						type: "error",
					});
				}, 30);
				return;
			}
			if (that.DietForm.snackWeight.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的零食重量文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 35);
				return;
			} else if (that.DietForm.snackWeight < 1) {
				that.$message.error("零食重量填写不正确！");
				return;
			} else {
				let decimalW = that.DietForm.snackWeight.split('.');
				if(decimalW.length > 1 && decimalW[1].length > 2) {
					that.$message.error("零食重量超出范围，最多2位小数！");
					return;
				}
			}

			that.csiiLifeMedicalForm = that.DietForm;
			that.onSubmit();
		},
		//血糖检测
		BLsubmit() {
			let that = this;
			if (that.bloodSugarCheck.bloodSugarCheckResult.length > 9) {
				that.$message({
					message: "血糖检测结果填写过长，最多可以填写9个字！",
					type: "error",
				});
				return;
			}
			
			that.csiiLifeMedicalForm = that.bloodSugarCheck;
			that.onSubmit();
		},
		//详情
		getCsiiDetail() {
			let that = this;
			if (that.uuid !== null) {
				that.$axios.get('/api/api/patientCsiiLifestyleRecord/detail/' + that.uuid).then(res => {
					if (res.data.code === 200) {
						that.CsiiDetail = res.data.data;
						if (that.CsiiDetail.snackTime !== null) {
							that.CsiiDetail.snackTime = that.CsiiDetail.snackTime.split(' ')[1].substring(0,5);
						}
						if (that.CsiiDetail.lunchTime !== null) {
							that.CsiiDetail.lunchTime = that.CsiiDetail.lunchTime.split(' ')[1].substring(0,5);
						}
						if (that.CsiiDetail.dinnerTime !== null) {
							that.CsiiDetail.dinnerTime = that.CsiiDetail.dinnerTime.split(' ')[1].substring(0,5);
						}
						if (that.CsiiDetail.breakfastTime !== null) {
							that.CsiiDetail.breakfastTime = that.CsiiDetail.breakfastTime.split(' ')[1].substring(0,5);
						}
						if (that.CsiiDetail.bloodSugarCheckTime !== null) {
							that.CsiiDetail.bloodSugarCheckTime = that.CsiiDetail.bloodSugarCheckTime.split(' ')[1].substring(0,5);
						}

					} else {
						that.CsiiDetail = null
					}
				})
			} else if (that.$route.query.CsiiDetail !== null) {
				that.CsiiDetail = that.$route.query.CsiiDetail;
			}
		},
		//创建信息
		onSubmit() {
			let that = this;
			that.$set(that.csiiLifeMedicalForm, "userUuid", that.patientUuid);
			if (that.uuid == null) {
				that.$axios({
					method: "post",
					url: "/api/api/patientCsiiLifestyleRecord/create",
					data: that.csiiLifeMedicalForm
				}).then(res => {
					if (res.data.code === 200) {
						that.CsiiDetail = res.data.data;
						this.uuid = res.data.data.uuid;
						that.$message({
							message: "保存成功！",
							type: "success",
						});
						that.$router.push({
							path: "/AddCsiiLifestyleList",
							query: {
								patientUuid: that.patientUuid,
								uuid: that.uuid
							}
						});
						that.getCsiiDetail();
					} else {
						that.$message({
							message: res.data.message,
							type: "error"
						});
					}
				});

			} else {
				that.$set(that.csiiLifeMedicalForm, "uuid", that.uuid);
				console.log("更新");
				console.log(that.csiiLifeMedicalForm);
				if (this.patientUuid !== 'undefined') {
					that.$axios({
						method: "post",
						url: "/api/api/patientCsiiLifestyleRecord/update",
						data: that.csiiLifeMedicalForm
					}).then(res => {
						if (res.data.code === 200) {
							that.CsiiDetail = res.data.data;
							that.uuid = res.data.data.uuid
							console.log(that.CsiiDetail)
							that.$message({
								message: "保存成功！",
								type: "success",
							});
							that.$router.push({
								path: "/AddCsiiLifestyleList",
								query: {
									patientUuid: that.patientUuid,
									uuid: that.uuid
								}
							});
							that.getCsiiDetail();
						} else {
							that.$message({
								message: res.data.message,
								type: "error"
							});
						}
					});
				} else {
					that.$message({
						message: '用户不能为空',
						type: "error"
					});
				}
			}
		},
	}
}
